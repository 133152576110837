/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import LazyBackground from '../common/lazy-background-loader';
import LazyImage from '../common/lazy-img-loader';
import ArrowBounce from '../common/arrow-bounce';
import './story.css';

class Story extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerWidth: null,
      isClicked: false,
    };
    this.onClick = this.onClick.bind(this);
    this._resizeListener = null;
  }

  componentDidMount() {
    this._resizeListener = () => {
      this.setInnerWidth();
    };
    window.addEventListener('resize', this._resizeListener);
    this.setInnerWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeListener);
  }

  onClick() {
    this.setState(prevState => (
      { isClicked: !prevState.isClicked }
    ));
  }

  setInnerWidth() {
    this.setState({ innerWidth: window.innerWidth });
  }

  getStoryText(style) {
    return (
      <div style={style}>
        <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Our Story</h5>
        <div>Like any good drama, our story started off as an unlikely pairing.  Having attended Middle and High School together, we had known of each other&apos;s existence, but never had any reason to move beyond that stage. In Vincent&apos;s mind, Betty was a loud, HK fob, and in Betty&apos;s, it was Vincent who?</div>
        <br />
        <div>It wasn&apos;t until the Fall of 2014, that as faith would have it, that we started to work together at SquareTrade.   During the Giant’s 2014 World Series run, while hanging out and cheering on the Giants, an opportunity presented itself for the two of us to actually get to know each other.  We had both lived in the same neighborhood in San Francisco, so we would take the train home together after those long nights of drinking and cheering. Being a little tipsy, Vincent let his guard down and Betty just asked a million questions. And that&apos;s how we began…</div>
        <br />
        <div>After the Giants won the World Series and the alcohol wore off, we tackled the idea of a relationship very logically. Before we officially started, we drew out a pro and cons list where the cons section far outweighed the pros in that there were no words on that pro&apos;s section except for a picture of rainbows and butterflies. It was then that Vincent decidedly said, &quot;Forget the list, let’s do this!&quot;. And that&apos;s what got us here today, a leap of faith.</div>
        <br />
        <div>The past 5 years would be filled with lots of memories. We&apos;ve travelled the world, seen each other grow, and shed a few tears along the way. And that brings us to now, where we celebrate our union with our closest friends and family who have been cheering us on from the very beginning.</div>
      </div>
    );
  }

  generateMobileContent() {
    const src = '/splash.jpeg';
    const { isClicked } = this.state;
    const isActive = isClicked ? 'active' : '';
    const backTextStyling = {
      zIndex: 2,
      padding: '2rem',
      overflowY: 'scroll',
    };
    return (
      <div className="story-container">
        { isClicked ? '' : <ArrowBounce /> }
        <div className={`flipper-container ${isActive}`} onClick={this.onClick}>
          <div className="flipper">
            <div className="front story-image-container">
              <LazyBackground src={src} />
            </div>
            <div className="back story-text-container">
              {this.getStoryText(backTextStyling)}
              <div className="grey-overlay" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  generateDesktopContent() {
    const src = '/splash.jpeg';
    return (
      <div className="story-container">
        <div className="grey-overlay" />
        <div className="story-image-container">
          <div>
            <LazyImage src={src} />
          </div>
        </div>
        <div className="story-text-container">
          <div className="story-text">
            {this.getStoryText()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { innerWidth } = this.state;
    const isMobile = innerWidth <= 767;
    // const hidden = {
    //   display: isClicked ? 'none' : 'flex',
    // };
    // const hideStoryText = {
    //   display: innerWidth <= 767 ? 'none' : 'flex',
    // };
    if (innerWidth === null) {
      return '';
    }
    return isMobile ? this.generateMobileContent() : this.generateDesktopContent();
  }
}

// <img className="story-image" src={`${process.env.PUBLIC_URL}/splash.jpeg`} />

export default Story;
