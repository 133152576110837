/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './registry.css';
import LazyImage from '../common/lazy-img-loader';

const Registry = () => {
  const src = '/bchow_venmo.jpg';
  return (
    <div
      className="section-container"
    >
      <div className="grey-overlay" />
      <div style={{ padding: '2rem', zIndex: 2 }}>
        <div style={{ marginBottom: '1rem' }}>
          <h4 className="section-header">Registry</h4>
        </div>
        <div className="text-content-section">
          <div>We are thankful that you can join our wedding and celebrate us. At this time, our home is filled with everything we need. It is our dream to buy our first home together, so any monetary gifts are greatly appreciated and helpful in building our future.</div>
        </div>
        <div className="text-content-section">
          <div>
            If you would to pitch in, you can do so via Venmo either by clicking the link&nbsp;
            <a href="https://venmo.com/code?user_id=1604787820822528875">here</a>
            , or by scanning the image below.
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <div className="venmo-image-container">
                <LazyImage src={src} />
              </div>
            </div>
          </div>
        </div>
        <div className="text-content-section">
          <h4 style={{ textAlign: 'center', marginTop: '4rem' }}>Thank you for sharing our special day with us!</h4>
        </div>
      </div>
    </div>
  );
};

export default Registry;
