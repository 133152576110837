/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/require-default-props */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './wedding-party-member.css';

function WeddingPartyMember({
  index, viewport, name, src, content,
}) {
  const [animationClass, setAnimationClass] = useState();
  const listRef = useRef(null);
  const isEven = index % 2 === 0;
  useEffect(() => {
    const rect = listRef.current.getBoundingClientRect();
    const isVisible = !(
      Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / (rect.height / 1)) * 100)) < 40 || Math.floor(100 - ((rect.bottom - viewport.height) / rect.height) * 100) < 40);
    if (isVisible) {
      setAnimationClass(isEven ? 'wedding-party-visible-left' : 'wedding-party-visible-right');
    } else {
      setAnimationClass('not-visible');
    }
  });
  return (
    <li ref={listRef} className={`wedding-party-item ${animationClass}`}>
      <div className="wedding-party-item-content" style={{ justifyContent: isEven ? 'flex-start' : 'flex-end' }}>
        <div className="wedding-party-item-container" style={{ width: '100%', flexDirection: isEven ? 'row' : 'row-reverse', display: 'flex' }}>
          <div className="wedding-party-item-info">
            <h2>{name}</h2>
            <img className="wedding-party-item-image" src={src} alt="image" style={{ borderRadius: '100%' }} />
          </div>
          <div style={{
            width: '100%', display: 'flex', justifyContent: isEven ? 'flex-start' : 'flex-end', paddingLeft: '10px', paddingRight: '10px',
          }}
          >
            {content}
          </div>
        </div>
      </div>
    </li>
  );
}

WeddingPartyMember.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  viewport: PropTypes.object,
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  content: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  src: PropTypes.string,
};

export default WeddingPartyMember;
