const WeddingParty = [
  {
    id: 1,
    name: 'Lawrence Lim',
    src: 'https://live.staticflickr.com/65535/48469007136_27a14a378a_q.jpg',
    content: 'You know that one kid that your parents always compared you to? That was Lawrence for me. He isn’t just smart, but an extremely talented puzzle solver and an all-around rational human being. As we journeyed from middle school to college together, I’ve always looked for advice from him for whatever life throws at you.',
  },
  {
    id: 2,
    name: 'Sandy Huynh',
    src: 'https://live.staticflickr.com/65535/48198299351_0db336376a_q.jpg',
    content: 'I’ve known Sandy since the 4th grade, my fellow July baby who is 3 days older than I am. She is one of the chillest person that I know, always have her sh*t together. She’s the girl that I trust to go on any crazy trip with because she always makes the best of all any crazy situations.',
  },
  {
    id: 3,
    name: 'Teddy Chung',
    src: 'https://live.staticflickr.com/65535/48236969922_f0c690c9ec_q.jpg',
    content: 'The earliest memory I have of Teddy was of teaming up with him in trivia to win donuts, and if you know Ted, you know that we feasted. He is the hardest working, most knowledgeable person I know of. There isn’t any task too challenging for him, and his competitive nature pushes you to be better. I’m grateful that I’ve had the opportunity to team up with Teddy and to learn from his awesomeness.',
  },
  {
    id: 4,
    name: 'Sandy Tan',
    src: 'https://live.staticflickr.com/65535/48185251152_ff26670c56_o.jpg',
    content: 'You know that girlfriend that you can spend hours on the phone with, Sandy is that friend for me.  Sandy is caring and always make sure that we’re taken care of. She has always been there to celebrate all my ups and all my downs. Without her, I would be lost in navigating through the journey of love.',
  },
  {
    id: 5,
    name: 'Samuel Lam',
    src: 'https://live.staticflickr.com/65535/48223870651_64eb5691b1_q.jpg',
    content: 'Some guy from high school. To be honest, I never really knew Sam until college, where we would spend the next few years playing Dota. Sam is the most carefree goofball that I’ve had the pleasure to hang out with. Going to the bars and clubs with this guy always equaled a great time.  So unfortunately for me, I never got to know him in high school, but I was lucky to meet him in college',
  },
  {
    id: 6,
    name: 'Jackie Lin',
    src: 'https://live.staticflickr.com/65535/48185191461_7503aacb3c_o.jpg',
    content: 'I have a lot of fun with Jackie, she will always let us know where the latest concerts and the best deals are. She is logical and provides the best insights on real world stuff. I can always count on her to be there and give me the truth when I need it.',
  },
  {
    id: 7,
    name: 'Raymond Wang',
    src: 'https://live.staticflickr.com/65535/48198299311_f6a56b560d_q.jpg',
    content: 'I met Raymond during college. Like any good friend, I’ve always given Raymond crap, but he always took it with a smile. That\'s the thing with Raymond, he is one of the nicest, most humble, and down to earth person that I’ve had the pleasure of meeting.I don’t deserve Raymond’s friendship, but feel honored to have it.',
  },
  {
    id: 8,
    name: 'Anna Lin',
    src: 'https://live.staticflickr.com/65535/48185246622_b42f3f5bb0_o.jpg',
    content: 'My beauty guru and baking buddy.  Anna is always down to try the food I make. We’ve spent so much time baking macarons and taking pictures for the gram. She is so artsy that I always hope that she has me for secret santa because all her gifts are so thoughtful.',
  },
];

export default WeddingParty;
